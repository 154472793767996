import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor (
        private router: Router,
        private authentidcationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authentidcationService.currentUserValue
    
        if(currentUser) {
            return true
        }

        this.router.navigate(['/login'], { queryParams: {returnUrl: state.url}})
        return false
    }
}