import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { User } from 'src/app/_models/user';
import { OnhandService } from 'src/app/_services/onhand.service';
import { Item } from 'src/app/_models/item';
import { forkJoin } from 'rxjs';
import { ProductService } from 'src/app/_services/product.service';
import { ProductCatalog } from 'src/app/_models/product-catalog';

@Component({
  selector: 'app-stock-take',
  templateUrl: './stock-take.component.html',
  styleUrls: ['./stock-take.component.css']
})
export class StockTakeComponent implements OnInit {

  currentUser: User
  onhandItems: Item[]
  productCatalog: ProductCatalog

  isLoading: boolean

  constructor(private authendticationService: AuthenticationService, private onhandService: OnhandService, private productService: ProductService) {
    this.authendticationService.currentUser.subscribe(x => this.currentUser = x)
  }

  ngOnInit() {
    if (!this.onhandService.onhandItems) {
      this.isLoading = true
      let onhand = this.onhandService.getOnhand()
      let productCatalog = this.productService.getProducts()
      forkJoin([onhand, productCatalog])
        .subscribe(data => {
          this.productService.productCatalog = new ProductCatalog(data[1])
          this.onhandService.onhandItems = data[0]
          this.onhandItems = this.onhandService.onhandItems
          this.productCatalog = this.productService.productCatalog
          this.isLoading = false
        })
    }
  }

}
