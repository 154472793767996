import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProductAdapter, Product } from '../_models/product';
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';
import { IGetProductCatalogResponse } from '../_interface/IGetProductCatalogResponse';
import { map } from "rxjs/operators";
import { ProductCatalog } from '../_models/product-catalog';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productCatalog: ProductCatalog

  constructor(private httpClient: HttpClient, private adapter: ProductAdapter) { }

  getProducts(): Observable <Product[]>{
    return this.httpClient.get(environment.apiUrl+"get-product-catalog.json")
      .pipe(map((data: IGetProductCatalogResponse) => data.ProductCatalogue.Product.map(product => this.adapter.adapt(product))))
  }
}
