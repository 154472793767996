import { Component, OnInit, Input } from '@angular/core';
import { OnhandService } from 'src/app/_services/onhand.service';
import { Item } from 'src/app/_models/item';
import { ProductService } from 'src/app/_services/product.service';
import { ProductCatalog } from 'src/app/_models/product-catalog';


@Component({
  selector: 'app-onhand-item',
  templateUrl: './onhand-item.component.html',
  styleUrls: ['./onhand-item.component.css']
})
export class OnhandItemComponent implements OnInit {

  onhandItems: Item[]
  displayedColumns: string[] = ['position', 'itemName', 'qty']
  dataSource = []
  productCatalog: ProductCatalog

  constructor(private onhandService: OnhandService, private productService: ProductService) { }

  ngOnInit() {
    this.onhandItems = this.onhandService.onhandItems
    this.productCatalog = this.productService.productCatalog
  }

}
