import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { log } from 'util';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  returnUrl: string

  constructor (private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute) { 
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/'])
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
  }

  login(login){
    this.authenticationService.login(login.userName, login.password)
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl])
        },
        error => {
          console.log(error);
        }
      )
    
  }

}
