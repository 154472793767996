import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OnhandService } from 'src/app/_services/onhand.service';
import { log } from 'util';
import { ActivatedRoute, Router } from '@angular/router';
import { Item } from 'src/app/_models/item';
import { ProductService } from 'src/app/_services/product.service';
import { ProductCatalog } from 'src/app/_models/product-catalog';
import { Product } from 'src/app/_models/product';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-scan-serial',
  templateUrl: './scan-serial.component.html',
  styleUrls: ['./scan-serial.component.css']
})
export class ScanSerialComponent implements OnInit {

  multiScan: boolean = false
  startSerial: string = ""
  endSerial: string = ""

  selectedItemCode: string
  selectedItem: Item

  displayScanner = false
  isSearching = false

  product: Product

  @ViewChild('scanSerial') public scannSerialForm: NgForm

  constructor(private onhandService: OnhandService, private route: ActivatedRoute, private router: Router, private productService: ProductService, private snackBar: MatSnackBar) { }

  ngOnInit() {

    if (!this.onhandService.onhandItems || !this.productService.productCatalog) {
      this.router.navigate(['/stock-take'])
      return
    }

    this.route.params
      .subscribe(params => {
        this.selectedItemCode = params['itemCode'];
        this.selectedItem = this.findItem(this.selectedItemCode)
        this.product = this.productService.productCatalog.getProductBy(this.selectedItemCode)
        console.log(this.selectedItem);
      })
  }

  startScanner() {
    this.displayScanner = true
  }

  stopScanner() {
    this.displayScanner = false
  }

  submitSerial(scanForm: NgForm) {
    this.isSearching = true

    this.startSerial = scanForm.value['startSerial']
    this.endSerial = scanForm.value['endSerial']
    this.multiScan = scanForm.value['multiScan']

    let serials = this.selectedItem.prepareSerialForCheck(this.startSerial, this.endSerial, this.multiScan)
    this.selectedItem.scanSerial(serials, this.multiScan)

    this.startSerial = ""
    this.endSerial = ""
    this.multiScan = false

    this.isSearching = false
  }


  findItem(itemCode) {
    var ret = null
    this.onhandService.onhandItems.forEach(item => {
      if (item.itemCode == itemCode) {
        ret = item
      }
    });
    return ret
  }

  /*
  checkSerial() {
    this.isSearching = true
    let serials = this.selectedOnhandItem.prepareSerialForCheck(this.startSerial, this.endSerial, this.multiScan)
    this.selectedOnhandItem.scanSerial(serials, this.multiScan)
    this.isSearching = false
    this.startSerial = ""
    this.endSerial = ""
  }

  */

  setScannerStatus(status) {
    this.displayScanner = status
  }

  setScannedValue(scannedValue) {

    if (this.multiScan) {
      if (this.startSerial.length == 0) {
        this.displayScanner = false
        this.startSerial = scannedValue
        scannedValue = ""
        this.endSerial = ""
      } else {
        if (this.startSerial != scannedValue) {
          this.displayScanner = false
          this.endSerial = scannedValue
          //this.checkSerial()
        }
      }
    } else {
      this.displayScanner = false
      this.startSerial = scannedValue
      //this.checkSerial()
    }
    
  }

}
