import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { FormsModule } from "@angular/forms";

import { LoginComponent } from './_components/login/login.component';
import { BarcodeScannerComponent } from './_components/barcode-scanner/barcode-scanner.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HomeComponent } from './_components/home/home.component';
import { NavComponent } from './_components/nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { StockTakeComponent } from './_components/stock-take/stock-take.component';
import { OnhandItemComponent } from './_components/onhand-item/onhand-item.component';
import { ScanSerialComponent } from './_components/scan-serial/scan-serial.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BarcodeScannerComponent,
    HomeComponent,
    NavComponent,
    StockTakeComponent,
    OnhandItemComponent,
    ScanSerialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BarecodeScannerLivestreamModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi:true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
