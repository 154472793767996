import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Item, ItemAdapter } from "../_models/item";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGetStockResponse } from '../_interface/IGetStockResponse';

@Injectable({
  providedIn: 'root'
})
export class OnhandService {

  onhandItems: Item[]

  constructor(private httpClient: HttpClient, private adapter: ItemAdapter) { }

  getOnhand(): Observable <Item[]>{
    return this.httpClient.get(environment.apiUrl+"get-stock.json")
      .pipe(map((data: IGetStockResponse) => data.ItemList.map(item => this.adapter.adapt(item))))
  }
}
