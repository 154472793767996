import { Serial } from './serial';
import { Inject, Injectable } from '@angular/core';
import { Adapter } from '../_interface/adapter';

export class Item {
    itemName: string
    itemCode: string
    itemType: string
    itemQty: number
    itemBatch: string
    
    scannedItemQty: number
    matchedItemQty: number
    matchedPercentage: number
    serialNumbers: Serial[]
    
    constructor(serialNumber: Serial[], itemName: string, itemCode: string, itemType: string, itemQty: number, itemBatch: string) {
        this.serialNumbers = serialNumber
        this.itemCode = itemCode
        this.itemBatch = itemBatch
        this.itemName = itemName
        this.itemType = itemType
        this.itemQty = itemQty

        this.scannedItemQty = 0
        this.matchedItemQty = 0
    }

    scanSerial(serialNumbers, isMultiScan = false) {
        if(this.itemBatch == "10000000"){
            serialNumbers.forEach(serial => {
                if(isMultiScan) {
                    this.findAndUpdateSim(serial)
                } else {
                    this.findAndUpdate(serial)
                }
            });
        } else {
            serialNumbers.forEach(serial => {
                this.findAndUpdate(serial)
            });
        }

        this.updateMatchedCount(this.itemCode)
    }

    prepareSerialForCheck(startSerial: string, endSerial: string, multiScan: boolean) {
        var localStartSerial: number
        var localEndSerial: number
        var serialListToCheck = []

        if (multiScan) {
            if (this.itemBatch == "10000000") {
                localStartSerial = Number(startSerial.substring(6, startSerial.length - 1))
                localEndSerial = Number(endSerial.substring(6, endSerial.length - 1))
            } else {
                localStartSerial = Number(startSerial)
                localEndSerial = Number(endSerial)
            }

            this.scannedItemQty = (localEndSerial - localStartSerial) + 1
            let localSerial = localStartSerial - 1
            for (let i = 0; i < this.scannedItemQty; i++) {
                localSerial = localSerial + 1
                    serialListToCheck.push(localSerial)
            }
        } else {
                serialListToCheck.push(startSerial)
        }

        return serialListToCheck
    }

    findAndUpdate(findSerial) {
        this.serialNumbers.forEach(serial => {
            if (serial.serialNumber == String(findSerial) && !serial.found) {
                serial.found = true
                this.matchedItemQty = this.matchedItemQty + 1
                return true
            }
        });
        return false
    }

    findAndUpdateSim(findSerial) {
        this.serialNumbers.forEach(serial => {
            if(serial.serialNumber.substring(6, serial.serialNumber.length - 1) == findSerial && !serial.found){
                serial.found = true
                 this.matchedItemQty =  this.matchedItemQty + 1
                 return true
            }
        });
        return false
    }

    updateMatchedCount(itemCode) {
        this.matchedItemQty = 0
        this.serialNumbers.forEach(serial => {
            if(serial.found)
                this.matchedItemQty = this.matchedItemQty + 1
        });

        this.matchedPercentage = (this.matchedItemQty/this.itemQty)*100
    }
    
}

@Injectable({
    providedIn: 'root'
})

export class ItemAdapter implements Adapter<Item> {
    adapt(item: any){
         var serialArray = []
         let itemName = ""
         let itemCode = item.SerialNumberDetails[0].Item
         let itemType = item.SerialNumberDetails[0].ItemType
         let itemBatch = item.SerialNumberDetails[0].SIMBatch
         let itemQty =  Number(item.Quantity)
         item.SerialNumberDetails.forEach(item => {
             let serial = new Serial(item.SerialNumber)
             serialArray.push(serial)
         });
         let serialNumbers = serialArray

        return new Item(
            serialNumbers,
            itemName,
            itemCode,
            itemType,
            itemQty,
            itemBatch
        )
    }


}