import { 
    MatToolbarModule, 
    MatSidenavModule, 
    MatIconModule,
    MatMenuModule, 
    MatFormFieldModule, 
    MatInputModule,
    MatListModule, 
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
     } from "@angular/material";

import { NgModelGroup } from '@angular/forms';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        MatToolbarModule, 
        MatSidenavModule, 
        MatIconModule,
        MatMenuModule, 
        MatInputModule,
        MatListModule, 
        MatCardModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatTableModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule],

    exports: [MatToolbarModule, 
        MatSidenavModule, 
        MatIconModule,
        MatMenuModule, 
        MatInputModule,
        MatListModule, 
        MatCardModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatTableModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule]
})
export class MaterialModule { }