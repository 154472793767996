import { Product } from './product';

export class ProductCatalog {
    products: Product[]

    constructor(products: Product[]) {
        this.products = products
    }

    getProductBy(itemCode: string): Product {
        var ret: Product
        this.products.forEach(product => {
            if (product.itemCode == itemCode)
                ret = product
        });
        return ret
    }
}

