import { Inject, Injectable } from '@angular/core';
import { Adapter } from '../_interface/adapter';

export class Product {
    itemCode: string
    itemName: string
    productDescription: string
    itemType: string  
    
    constructor(itemCode: string, itemName: string, productDescription: string, itemType: string){
        this.itemCode = itemCode
        this.itemName = itemName
        this.itemType = itemType
        this.productDescription = this.productDescription
    }
}

@Injectable({
    providedIn: 'root'
})
export class ProductAdapter implements Adapter<Product> {
    adapt(product: any){
        return new Product(product.ItemCode, product.ItemName, product.ProductDescription, product.ItemType)
    }
}