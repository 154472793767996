import { Component, ViewChild, AfterViewInit, Output } from '@angular/core';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.css']
})
export class BarcodeScannerComponent implements AfterViewInit {

  @Output() scannedValue = new EventEmitter()
  @Output() isScannerStarted = new EventEmitter()

  @ViewChild(BarecodeScannerLivestreamComponent)
  barecodeScanner: BarecodeScannerLivestreamComponent

  barcodeValue;
  isLoading = true

  ngAfterViewInit() {
    this.startScanner()
  }
  
  startScanner() {
    this.barecodeScanner.start().then(() => {
      this.isScannerStarted.emit(true)
      this.isLoading = false
    })
  }

  stopScanner() {
    this.barecodeScanner.stop()
    this.isScannerStarted.emit(false)
  }

  onValueChanges(result) {
    if (!isNaN(result.codeResult.code)) {
      if (result.codeResult.code.toString().substring(0, 3) == "894" && result.codeResult.code.length == 20) {
        this.barcodeValue = result.codeResult.code;
        this.scannedValue.emit(this.barcodeValue)
      } else if (result.codeResult.code.length == 8 || result.codeResult.code.length == 15) {
        this.barcodeValue = result.codeResult.code;
        this.scannedValue.emit(this.barcodeValue)
      }
    }
  }
}
