import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";
import { User } from "../_models/user";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>

  constructor (private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')))
    this.currentUser = this.currentUserSubject.asObservable()
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value
  }

  login(username: string, password: string){
    //return this.httpClient.post(environment.apiUrl+"login", {username, password})
     return this.httpClient.get(environment.apiUrl+"login.json")
    .pipe(map((user: User) => {
      if(user && user.token) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user)
      }
      return user
    }))
  }

  logout(){
    localStorage.removeItem('currentUser')
    this.currentUserSubject.next(null);
  }
}
